<template>
  <div>
    <v-row>
        <v-col lg="12" cols="12">
          <warehouse-entrance @rowSelected="rowSelected($event)"></warehouse-entrance>
        </v-col>
        <v-col lg="12" cols="12">
          <warehouse-entrance-detail :WreID="WreID"></warehouse-entrance-detail>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import WarehouseEntrance from './WarehouseEntrance.vue'
import WarehouseEntranceDetail from './WarehouseEntranceDetail.vue';

export default {
  components: { WarehouseEntrance, WarehouseEntranceDetail },
  data(){
    return {
      WreID: 0,
    };
  },

  methods: {
    rowSelected(rows) {
      this.WreID = rows[0].WreID;
      console.log(this.WreID);
    },
  },
};
</script>