<template>
    <div>
        <s-crud
            title="Entrada de Material"
            :filter="filter"
            height="150"
            :config="configWarehouseEntrance"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            :edit="this.$fun.getSecurity().IsLevelAdmin"
            
            @rowSelected="rowSelected($event)"
            @save="save($event)"
        >
        <!-- :remove="this.$fun.getSecurity().IsLevelAdmin" -->

            <template slot-scope="props">
                <v-col>
                    <v-row>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-date label="Fecha" v-model="props.item.WreDate"/>
                        </v-col>
                        <v-col cols="12" class="s-col-form" lg="6">
                            <s-text label="N° Requerimiento" v-model="props.item.WreNumberRequirement"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="s-col-form" md="12" lg="12">
                            <s-text  label="Razón" v-model="props.item.WreReason"></s-text>
                        </v-col>
                    </v-row>
                </v-col>
            </template>

        </s-crud>
    </div>
</template>

<script>
import _SWarehouseEntranceService from "@/services/Technicalassistance/WarehouseEntranceService";

export default {
  components: {},
  props: {

  },
  data: () => ({
    configWarehouseEntrance: {
        model: {
            WreID: "ID",
            WreNumberRequirement: "string",
            WreReason: "string",
            SecStatus: "int",
        },
        service: _SWarehouseEntranceService,
        headers: [
            {text: "ID", value: "WreID"},
            {text: "N° Requerimiento", value: "WreNumberRequirement"},
            {text: "Razón", value: "WreReason"},
            
        ],
    },
  }),
  methods: {
    Initialize() {
      this.filter = {  };
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
            this.$emit('rowSelected', rows);
        }
    },

    save(item){
        if(item.WreNumberRequirement == ""){
            this.$fun.alert("Ingrese número de requerimiento", "error");
            return;
        }
        if(item.WreReason == ""){
            this.$fun.alert("Ingrese razón", "error");
            return;
        }
        console.log("item", item);
        item.SecStatus = 1;
        item.save();
    },
    
  },

  watch:{
      
  },
  created() {
    this.filter = {};
  },
};
</script>
