<template>
    <div>
        <s-crud
            title="Lista de Entrantes"
            :filter="filter"
            height="210"
            :config="configDriverVehicle"
            :add="this.$fun.getSecurity().IsLevelAdmin"
            @rowSelected="rowSelected($event)"
            @save="saveAll($event)"
            deleteNoFunction
            @delete="deleteMaterialDetail"
            ref="crudMaterial"
        >
        <!-- :remove="this.$fun.getSecurity().IsLevelAdmin" -->

            <template slot-scope="props">
                <v-col cols="12">
                    <v-row>
                        <s-toolbar 
                            remove 
                            add  
                            @add="addMaterial(props.item)"
                            @removed="removeMaterial()"
                            label="Detalle Material"
                        >
                        </s-toolbar>
                        <!-- color="primary"  -->
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="s-col-form"  lg="6" md="6">
                            <s-article-search v-model="objArticle"></s-article-search>
                        </v-col>
                        <v-col cols="6" class="s-col-form" lg="6" md="6">
                            <s-text label="Cantidad" number v-model="props.item.WedCount"/>
                        </v-col>

                        <v-col cols="12">
                            <v-data-table
                            v-model="arrayMaterial"
                            :headers="headersDataTable"
                            :items="detailMaterial"
                            item-key="ArtID"
                            show-select 
                            class="elevation-1">
                            </v-data-table>
                        </v-col>
                        <v-container>
                            <v-row justify="center">
                                <v-col lg="6">
                                    <v-btn @click="clearOption()" small block><v-icon x-small left>fas fa-undo-alt</v-icon>Limpiar</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                </v-col>
            </template>

        </s-crud>
    </div>
</template>

<script>
import _SWarehouseEntranceDetail from "@/services/Technicalassistance/WarehouseEntranceDetailService";
import SToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import sArticleSearch from "@/components/Utils/Logistics/SArticleSearch";

export default {
  components: {SToolbarPerson, sArticleSearch, },
  props: {
    WreID: { type: Number, default: 0 },
  },
  data: () => ({
      arrayMaterial: [],
    ReturPerson: null,
    dni: "",
    plate: "",
    filter: {},
    itemSelected: {},
    detailMaterial: [],
    objArticle:{
        
    },
    ObjTransport:{
            Transport:null,
    },
    configDriverVehicle: {
        model: {
            WedID: "ID",
            ArtID: "string",
            WedCount: "int",
            ArticleName: "string",
            WedObservation: "int",
            SecStatus: "int",
        },
        service: _SWarehouseEntranceDetail,
        headers: [
            {text: "ID", value: "WedID"},
            {text: "Articulo", value: "ArticleName"},
            {text: "Cantidad", value: "WedCount"},
        ],
    },
    headersDataTable:[
        {text:"ID",value:"ArtID"},
        {text: "Articulo", value: "ArtDescription"},
        {text: "Cantidad", value: "WedCount"}
    ],
  }),
  methods: {
    saveAll()
    {
        if(this.detailMaterial.length == 0)
        {
            this.$fun.alert("Grilla vacía", "error");
            return;
        }
        if(this.WreID == 0){
            this.$fun.alert("Ingrese Entrada", "error");
            return;
        }
        this.$fun.alert("Seguro de guardar", "question").then((r) => {
            if(r.value){
                _SWarehouseEntranceDetail
                .save(this.detailMaterial, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.clearOption();
                    this.$refs.crudMaterial.refresh();
                })
            }
        });
        //this.$refs.crudMaterialGuiaDetail.refresh();
    },
    deleteMaterialDetail()
    {
        this.itemSelected[0].SecStatus = 0;
        this.detailMaterial = this.itemSelected;
        console.log('this.detailMaterial', this.detailMaterial)
        this.$fun.alert("Seguro de eliminar", "question").then((r) => {
            if(r.value){
                _SWarehouseEntranceDetail
                .save(this.detailMaterial, this.$fun.getUserID())
                .then((resp) => {
                    this.$fun.alert("Guardado Correctamente", "success");
                    this.clearOption();
                    this.$refs.crudMaterial.refresh();
                })
            }
        });
    },
    addMaterial(item){
		console.log("agregar table", item);
        let obj = {
            WreID: this.WreID,
            ArtID: this.objArticle.ArtCode,
            WedCount: item.WedCount,
            WedObservation: "",
            SecStatus: 1,
            UsrCreateID: this.$fun.getUserID(),
            UsrUpdateID: this.$fun.getUserID(),
            ArtDescription: this.objArticle.ArtName,
        };
        if(!obj.ArtDescription != ""){
            this.$fun.alert("Ingrese artículo", "error");
            return;
        }
        if(item.WedCount == ""){
            this.$fun.alert("Ingrese cantidad", "error");
            return;
        }
        let amountBrought = parseInt(obj.IodEmpty) + parseInt(obj.IodFull);
        let count = parseInt(obj.IodCount);
        if(count < amountBrought){
            this.$fun.alert("Cantida de vacias y llenas excede la cantidad", "error");
            return;
        }
        this.detailMaterial.push(obj);
        this.clearForm(item);
    },
    removeMaterial(){
        this.arrayMaterial.forEach((element) => {
            this.detailMaterial = this.detailMaterial.filter(
            (x) => x.ArtID != element.ArtID
            );
        });
    },
    clearForm(item)
    {
        this.objArticle = null;
        item.WedCount = "";
    },

    clearOption()
    {
        this.detailMaterial = []
    },
    hadReturnPerson(value, item){
        item.NtpFullName = value.NtpFullName;
        item.DrvID = value.PrsID;
        this.dni = value.PrsDocumentNumber;
    },
    Initialize() {
        this.filter = { WreID: this.WreID };
    },
    rowSelected(rows)
    {
        if (rows.length > 0) {
            this.itemSelected = rows;
        }
    },

    /* save(item){
        item.VehID = this.ObjTransport.VehID;
        item.SecStatus = 1;
        item.save();
        this.Objvehiculo = {}
    }, */
    
  },

  watch:{
    WreID(){
        this.Initialize();
    }
  },
  created() {
    this.filter = {WreID: this.WreID};
  },
};
</script>
